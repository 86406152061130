import React from "react";
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const MarginTop = styled.div`
    width: 90%;
    margin:0 auto;
    margin-bottom: 70px;
    margin-top: 120px;
`;

const TextTitle = styled.h5`
    font-size: 1.4rem;
    font-weight: bold;
`;
const Button = styled.button`
    background: #fdfdfd;
    border:0;
`;

const Pointer = styled.span`
    color: #fff;
    display: inline-block;
    background: #00ad80;
    width: 25px;
    height: 25px;
    border-radius: 50%;
`;

const Introduction = () => {
    const url ='/toitu/';
    return (
        <MarginTop>
         <div className="row">
            <div className="col-sm-6">
                <div className="card border-0">
                <div className="card-body">
                    <TextTitle className="card-title">日程・時間割・受験料</TextTitle>
                    <p className="card-text">統一模試の年間日程や当日の時間割などを記載しています。</p>
                    <p className="card-text">&ensp;</p>
                    <Link  to={url + 'Schedule'} >
                        <Button >詳細はこちら　<Pointer>→</Pointer></Button>
                    </Link>
                </div>
                </div>
            </div>
            <div className="col-sm-6" style={{borderLeft: '.05rem solid #e8e6e6'}}>
                <div className="card border-0">
                <div className="card-body">
                    <TextTitle className="card-title">お知らせメール</TextTitle>
                    <p className="card-text">次回以降の試験結果の登録や高校入試に関わる情報をお知らせいたします。</p>
                    <p className="card-text">※予約受付け開始時にお知らせメールが届きます。</p>
                    <a href="https://toitumosi.jp/join/registmail.html" style={{color: '#00ad80'}}>
                        <Button >詳細はこちら　<Pointer>→</Pointer></Button>
                    </a>
                </div>
                </div>
            </div>
        </div>
         </MarginTop>
    );
}

export default Introduction;