import React from 'react';
import styled from 'styled-components';

const Heading = styled.h2`
    margin-bottom: 30px;
    padding: 15px;
    font-size: 24px;
    font-size: 1.5rem;
    background-color:#009d80;
    color: #fff;
    border-radius: 7px;
    margin-top: 50px;
`;
const Title = styled.h2`
    color: #009d80;
    text-align: center;
`;
const SubBack = styled.div`
    background-color: #f5f5f5;
    margin-bottom:30px;
`;
const SubTextLi = styled.li`
    margin-top: 3px;
    list-style-type: none; 
    position: relative; 
    padding-left: 0.6em;
    &:before {
        border-radius: 50%; /*丸くする*/
        width: 5px; /*点の幅*/
        height: 5px; /*点の高さ*/
        display: block;
        position: absolute; /*絶対配置*/
        left: 0; /*点の位置*/
        top: 0.6em; /*点の位置*/
        content: "";
        background: #009d80; /*点の色*/
    }
`;

const Advice = () => {
    return (
        <>
            <Heading>公立高校への合格は<span style={{fontSize: '28px'}}>『統一模試』</span>が決め手になる！</Heading>
            <p className="card-text">
                来年の公立高校入試は3月4・5日に実施される。合格は、この日までに五教科の入試問題をきちんと解けるようになるかどうかにかかっている。
            </p>
            <p className="card-text">   
                今すぐ君たちが始めなければならないことは、入試に向けての実戦力を養う受験勉強である。それには、公立高校志願者のための、信頼性の高い統一模試(年５回実施)の活用が欠かせない。
            </p> 
            <p className="card-text">   
                この統一模試における１回あたりの受験者数は回数を重ねる毎に増加していき、最終回までにはほとんどの生徒が何回かは必ず受験をする。受験したら志望校内の位置と判定だけでなく、他の学校・学科とも比較しながら、合格可能性の診断に活用してほしい。
            </p>
            <p className="card-text">   
                返送された答案をしっかりと見返し、自分の間違ったところをしっかりとやり直していくことが実力アップの最速の方法だ。回を重ねるごとに実力がついていると感じても、合格が決定するまでは決して勉強を中断してはいけない。最後までやりきることが、本番で発揮できる力をつけてくれる。
                今後、入試に必要な情報は、模試受験時に合わせてお知らせするので、必ず確認するようにしてください。
            </p>
            
            <SubBack className="card  mt-5 border-0" >
                <div className="card-body ">
                    <Title className="card-title">ー&ensp;「統一模試」の活用で学力アップと正確な判定を&ensp;ー</Title>
                    <h5 className="font-weight-bold mt-5">信頼性の高い高知県「統一模試」</h5>
                    <ul>
                        <SubTextLi>自分の学力が、中学校内の位置だけでなく、志望校の受験者の中ではどれぐらいの順位に なるのか、全体の中ではどのくらいになるのか、判定できる。</SubTextLi>
                        <SubTextLi>各回がすべて公立高校入試予想問題となっており、試験範囲が少しずつ進み全範囲を網羅できる。９月～１月にかけて全５回実施。</SubTextLi>
                    </ul>
                </div>
            </SubBack>
        </>
    );
}

export default Advice;
