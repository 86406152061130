import { Tab, Tabs } from 'react-bootstrap';
import MediaQuery from "react-responsive";
import Table from './ExaminationTable.jsx';
import Audio from './AudioList.jsx';
import SpBook from './SpBook/BookIntroduction.jsx';
import ScrollToTopOnMount from '../MainContents/AutoScrollTop'; 
import BookIntroduction from './BookIntroduction.jsx';
import styled from 'styled-components';
import PageTitle from '../MainContents/PageTitle/PageTitle.js';

// スタイル宣言
const MarginTop = styled.div`
    margin:0px auto;
    margin-top: 120px;
    width:80%;

`;
const Heading = styled.h2`
    margin-bottom: 30px;
    padding: 15px;
    font-size: 24px;
    font-size: 1.5rem;
    background-color:#009d80;
    color: #fff;
    border-radius: 7px;
`;
const Examination = () => {
  return(
    <MarginTop>
        <ScrollToTopOnMount />
        <PageTitle title={'高校入試情報'} englishText={'ExaminationInformation'} />
        <Heading>令和5年度　高知県立高校入試情報</Heading>
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
            <Tab  eventKey="home" title="A日程">
                <Table 
                    applicationPeriod={'1月28日(火)～1月30日(木)'}
                    changePeriod={'	2月4日(火)～2月6日(木)'}
                    searchDate={'3月4・5日'}  
                    passingAnnouncement={'3月13日(木)'}                        
                    testingDetail={'5教科・面接'}
                    recruitingCapacity={'入学定員の100%'}              
                />
            </Tab>
            <Tab eventKey="profile" title="B日程">
                <Table 
                    applicationPeriod={'3月14日(金)～3月17日(火)'}
                    changePeriod={'3月18日(火)'}
                    searchDate={'3月19(水)'}  
                    passingAnnouncement={'3月24日(月)'}                        
                    testingDetail={'5教科・面接'}
                    recruitingCapacity={'入学定員を満たすために必要な人数'}              
                />
            </Tab>
        </Tabs>

        {/* 入試の音楽ファイルを再生する */}
        <Audio />
        {/* モバイルサイズ */}
        <MediaQuery query="(max-width: 768px)">
            <SpBook />
        </MediaQuery>

        {/* ipad pro以上のサイズ */}
        <MediaQuery query="(min-width: 769px)">
            <BookIntroduction />
        </MediaQuery>
     
    </MarginTop>
  );
};

export default Examination;