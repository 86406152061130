import React from 'react';
import styled from 'styled-components';

const HrColor =  styled.hr`color: #00ad80`; 
const HeadingColor =  styled.p`color: #00ad80`; 
const Margin =  styled.div`
    margin-bottom: 80px;
    margin-top: 80px;
`; 
const BackColor = styled.div`
    background-color: #f5f5f5;
`;

const DetailColor = styled.p`
    color: #00ad80;
`;
const DetailColorMain = styled.p`
    color: #00ad80;
    font-weight: bold;
    margin-bottom: 0;
`;
const DetailColorBlack = styled.p`
    color: black;
    margin-bottom: 0;
    fonst-size:20px;
`;

const Iframe = styled.iframe`
    width: 100%;
    height: auto;
`;

const PageInfo = styled.p`
    text-align: center;
    font-weight: bold;
    margin-top: -5px;
    border-bottom: 2px solid #00ad80;
`;
// 会場を再選択するため会場選択できるところまで移動する
const pageTopAutoScroll = () => {
        window.scrollTo(0, 200) 
}


const MapImport = (props)=> {
    const {hallName, hallURL, Reserved, Detail,Detail2,Detail3,Detail4} = props;
    const  element = document.documentElement;
    const bottom = element.scrollHeight - element.clientHeight;
    window.scroll(0, bottom);
    let PublicMap = () => {
        var now = new Date();
        var end = new Date('2021/11/28 08:59:00'); 
        if(now > end) {
            // return <h6 className="font-weight-bold">第3回の申込みは10月25日 12時～申込み可能です。</h6>
            return <Iframe scrolling="no" src={Ifr}></Iframe>
        }else{
            return <Iframe scrolling="no" src={Ifr}></Iframe>
        }
    }
    // iframe URL作成
    const Ifr = `https://tosajuku.info/tsjk_webpage/reservation/statusConfirmation/statusLook.php?hallName=${hallName}`
    return (
        <>
            <Margin className="container">
                <div className="row">
                    {/* クリックされた会場のマップを表示する */}
                    <div id="map-position" className="col-sm-7">
                        <iframe src={hallURL} width="100%" height="400"  allowfullscreen="" loading="lazy" title="hallMap"></iframe>
                    </div>
                    <BackColor className="col-sm-5">
                        <HrColor />
                        <HeadingColor id="elem">会場の詳細</HeadingColor>
                        <p>会場名：{hallName}</p>
                        <p>予約&ensp;&ensp;：{Reserved==='予' ? '必要' : '不要'}</p>
                        <DetailColor>{Detail}</DetailColor>
                        <DetailColorMain>{Detail2}</DetailColorMain>
                        <DetailColorBlack>{Detail3}</DetailColorBlack>
                        <DetailColorBlack>{Detail4}</DetailColorBlack>
                        <HrColor />
                        
                        <HeadingColor>席状況</HeadingColor>
                        <PublicMap></PublicMap>
                        
                        <PageInfo onClick={() => pageTopAutoScroll()} >他の会場を選択する ↑</PageInfo>
                    </BackColor>
                </div>
            </Margin>
        </>
    );
}

export default MapImport;