import React from 'react'

const Answer = (props) => {
    return (
        <>
             <div className="card-body">
                <p class="card-text">{props.answer}</p>
                <p class="card-text">{props.answer2}</p>
                <p class="card-text">{props.answer3}</p>
                <p class="card-text">{props.answer4}</p>
                <p class="card-text">{props.answer5}</p>
                <p class="card-text">{props.answer6}</p>
            </div>
        </>
    )
}

export default Answer;