import React,{useState} from 'react';
import styled from 'styled-components';
import AudioPlayerYear from './AudioPlayerYear.jsx';

const AudioDiv = styled.div`
    width: 100%;
    height: 40vh;
    object-fit: cover;
    margin-left: 100px;
`;
const Arrow = styled.span`
    background: #fff;
    border-radius: 29px;
    padding: 0px 4px;
    color: #2cc12c;
    font-size: 17px;
    margin-left: 3px;
`;
const BookSubScope = styled.div`
    width: 100%;
    background-color: #fff;
    height: 60vh;
    margin-left: 100px;
    padding: 20px;
    margin-bottom: 40px;
`;

const Button = styled.button`
 margin-left:10%;
 border-radius: 3px;

 background: #00ad80;
 color: #fff;
 margin-top:20px;
 width: 100%;
 font-size: 14px;
 padding: 12px;

`;



const AudioListYear = (props) => {
    const [audio, setAudio] = useState(0);
    const ThaWhatTimes = (props) => {
        return (
            <div>
                <Button onClick={() => setAudio(props.year)}>20{props.year}年英語リスニング音声</Button>
            </div>
            
        );
    }
    return (
        <>
             {props.viewTrue!==undefined &&
                <BookSubScope className="card mb-3 border-0">
                    <div className="container">
                        <div className="row row-cols-4" style={{marginBottom: '15px'}}>
                            <div className="col"><ThaWhatTimes year={'23'} /></div>
                            <div className="col"><ThaWhatTimes year={'22'} /></div>
                            <div className="col"><ThaWhatTimes year={'21'} /></div>
                            <div className="col"><ThaWhatTimes year={'20'} /></div>
                        </div>
                        <div className="row row-cols-4" style={{marginBottom: '15px'}}>
                            <div className="col"><ThaWhatTimes year={'19'} /></div>
                            <div className="col"><ThaWhatTimes year={'18'} /></div>
                            <div className="col"><ThaWhatTimes year={'17'} /></div>
                            <div className="col"><ThaWhatTimes year={'16'} /></div>                            
                        </div>
                        <div className="row row-cols-4" style={{marginBottom: '15px'}}>
                            <div className="col"><ThaWhatTimes year={'15'} /></div>
                            <div className="col"><ThaWhatTimes year={'14'} /></div>
                            <div className="col"><ThaWhatTimes year={'13'} /></div>
                            <div className="col"><ThaWhatTimes year={'12'} /></div>
                            
                        </div>
                        <div className="row row-cols-4" style={{marginBottom: '15px'}}>
                            <div className="col"><ThaWhatTimes year={'11'} /></div>
                            <div className="col"><ThaWhatTimes year={'10'} /></div>
                        </div>
                        
                    </div>
                    <div className="text-center mt-3" >
                            <AudioPlayerYear playNumber={audio===0 ? false : audio} />
                    </div>
                </BookSubScope>
             }
              
        </>
    );
}

export default AudioListYear;