import React from 'react';
import styled from 'styled-components';
import {r5koritu,r4koritu,r3koritu,r1koritu,h31koritu,h30koritu,h29koritu,h28koritu, h27koritu, h26koritu, h25koritu, h24koritu, h23koritu, h22koritu} from '../Sound/index';
const HeadingColor = styled.h6`
    color: #00ad80;
    font-weight: bold;
`;
const NoDownload = 'nodownload';
let audio ="";

const AudioPlayerYear = props => {
    console.log(props.playNumber);
    // 値によって判断する
    switch (props.playNumber) {
        case '10':
            audio = h22koritu;
            break;
        case '11':
            audio = h23koritu;
            break;
        case '12':
            audio = h24koritu;
            break;
        case '13':
            audio = h25koritu;
            break;
        case '14':
            audio = h26koritu;
            break;
        case '15':
            audio = h27koritu;
            break;
        case '16':
            audio = h28koritu;
            break;
        case '17':
            audio = h29koritu;
            break;
        case '18':
            audio = h30koritu;
            break;
        case '19':
            audio = h31koritu;
            break;
        case '20':
            audio = r1koritu;
            break;
        case '21':
            audio = r3koritu;
            break;
        case '22':
            audio = r4koritu;
            break;
        case '23':
            audio = r5koritu;
            break;
    
        default:
            break;
    }   
    return (
        <>
        {
            props.playNumber!==false &&
            <>
            <HeadingColor>20{props.playNumber}年&ensp;英語リスニング音声</HeadingColor>
            <audio src={audio} preload="none" controls="controls" controlsList={NoDownload}></audio>
            </>
        }  
        {
            props.playNumber===false &&
            <>
            <HeadingColor>2023年&ensp;英語リスニング音声</HeadingColor>
            <audio src={r5koritu} preload="none" controls="controls" controlsList={NoDownload}></audio>
            </>
        }  
        </>
    );
}

export default AudioPlayerYear;