import React from 'react'
import {Navbar, Main, Schedule, Hall,UserResultTable,Examination,Question,ForToNextYear,Sample,ProtectionPolicy, Footer} from './index';
import { BrowserRouter, Route } from 'react-router-dom';


class App extends React.Component {

  render() {
    // URL 宣言
    const url ='/toitu/';
    return (
      <div>
        <BrowserRouter>
          <Navbar/>
          <Route path={url} exact component={Main} />
          <Route path={url + 'Hall'} component={Hall} />
          <Route path={url + 'Schedule'} component={Schedule} />
          <Route path={url + 'UserResultTable'} component={UserResultTable} />
          <Route path={url + 'Examination'} component={Examination} />
          <Route path={url + 'ForToNextYear'} component={ForToNextYear} />
          <Route path={url + 'UserResultTable/Sample'} component={Sample} />
          <Route path={url + 'Question'} component={Question} />
          <Route path={url + 'ProtectionPolicy'} component={ProtectionPolicy} />
          <Route path={url} component={Footer} />
        </BrowserRouter>
      </div>
    );
  }
}
export default App;