import React from 'react';
import styled from 'styled-components';

const MarginTop = styled.div`
    width: 90%;
    margin:0 auto;
    margin-bottom: 30px;
    margin-top: 30px;
`;

const SampleView = props => {
    const {title, text, imgSample} = props;
    return (
        <MarginTop className="card mb-3">
                <div className="row g-0">
                    <div className="col-md-6">
                        <div className="card-body ">
                            <h5 className="card-title text-center font-weight-bold" style={{color:'#00ad80'}}>{title}</h5>
                             <p className="card-text">{text}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card-body">
                            <img src={imgSample} width={'80%'} alt="" />
                        </div>
                    </div>
                </div>   
        </MarginTop>
    );   
}

export default SampleView;