import styled from 'styled-components';
import ScrollToTopOnMount from '../MainContents/AutoScrollTop'; 
const MarginTop = styled.div`
    margin:0 auto;
    width: 90%;
    margin-top: 100px;
`;
const Heading = styled.h3`
    margin-bottom: 30px;
    padding: 15px;
    font-size: 24px;
    font-size: 1.5rem;
    background-color:#009d80;
    color: #fff;
    border-radius: 7px;
`;

const ProtectionPolicy = () => {
    return (
        <MarginTop>
            <ScrollToTopOnMount />
            <Heading>Privacy Policy</Heading>
            <p className="card-text">
                統一模試実施研究会は（以下「当会」）は、以下のとおり個人情報保護に関するコンプライアンスプログラムを策定し、 個人情報保護の仕組みを構築し、会員塾・提携塾と共に個人情報保護の重要性の認識と取組みを徹底させることにより、 個人情報の保護を推進致します。
            </p>
            <Heading>１．個人情報の管理</Heading>
            <p className="card-text">
                当会は、お客さまの個人情報を正確かつ最新の状態に保ち、個人情報への不正アクセス・紛失・破損・改ざん・漏洩などを 防止するためセキュリティシステムの維持・管理体制の整備・担当者教育の徹底等の必要な措置を講じ、安全対策を実施し 個人情報の厳重な管理を行います。
            </p>
            <Heading>２．個人情報の利用目的</Heading>
            <p className="card-text">
                お客さまからお預かりした個人情報は、当会からのご連絡や業務のご案内やご質問に対する回答として、電子メールや資料 の送付に利用いたします。
            </p>
            <Heading>３．個人情報の第三者への開示・提供の禁止</Heading>
            <p className="card-text">
                当社は、お客さまよりお預かりした個人情報を適切に管理し、次のいずれかに該当する場合を除き、個人情報を第三者に 開示いたしません。 お客さまの同意がある場合 　a)お客さまが希望されるサービスを行うために、当会が業務を委託する業者に対して開示する場合 　b)法令に基づき開示することが必要である場合
            </p>
            <Heading>４．個人情報の安全対策</Heading>
            <p className="card-text">
                当会は、個人情報の正確性及び安全性確保のために、セキュリティに万全の対策を講じています。
            </p>
            <Heading>５．ご本人の照会</Heading>
            <p className="card-text">
                お客さまがご本人の個人情報の照会・修正・削除などをご希望される場合には、ご本人であることを確認の上、 対応させていただきます。
            </p>
            <Heading>６．法令、規範の遵守と見直し</Heading>
            <p className="card-text">
                当会は、保有する個人情報に関して適用される日本の法令、その他規範を遵守するとともに、 本ポリシーの内容を適宜見直し、その改善に努めます。
            </p>
            <Heading>統一模試実施研究会お問い合せ窓口</Heading>
            <div className="card-text">
                当会の個人情報の取扱に関するお問い合せは、統一模試実施研究会事務局までご連絡ください。<br />
                受付時間　：9:00〜17:00   <br/>
                統一模試実施研究会：088-885-3470    <br/>
                ※ただし、土・日曜・祝祭日、年末年始、夏季休業期間、ゴールデンウィーク期間は   <br/>
                翌営業日以降の対応とさせて頂きます。   <br/>
            </div>
            <p style={{marginBottom:'50px'}}></p>
        </MarginTop>
    );
}

export default ProtectionPolicy;