import React from 'react';
import ListItem from './HallDates.jsx';
import { Tab, Tabs } from 'react-bootstrap';
import styled from 'styled-components';

// スタイル宣言

const TableScope = styled.table`
  width: 90%;
  margin: auto;
  table-layout: fixed;
  word-wrap: break-word;
  overflow-x: auto;
  display: table;
  margin-bottom: 10px;
`;

const Th = styled.th`
    padding: 10px;
    vertical-align: middle !important;
`;
const SmallText = styled.small`color: #00ad80`;


const HallTable = () => {

  // 高知市内
  const cityIn = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  // 日付の取得
  const currentDate = new Date();
  const comparisonDate = new Date('2024-11-25');

  // 高知県東部 南国～室戸地区まで (日付に応じたエリアの番号) 室戸会場を非表示にするため
  const eastArea = currentDate >= comparisonDate ? [0, 1, 2, 3] : [0, 1, 2, 3, 4];

  // 高知県西部
  const westArea = currentDate >= comparisonDate ? [0, 1, 2, 3, 4 , 5] : [0, 1, 3, 4 , 5];

  const listHallCity = cityIn.map((number) =>
    <ListItem key={number.toString()} value={number} division={'city'} />
  );

  const listHallEast = eastArea.map((number) =>
    <ListItem key={number.toString()} value={number} division={'east'} />
  );

  const listHallWest = westArea.map((number) =>
    <ListItem key={number.toString()} value={number} division={'west'} />
  );

  return (
    <>

      {/* 本来はタブ形式にして表示するよう考えている */}
      <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" style={{ justifyContent: 'center' }}>
        <Tab eventKey="contact" title="西部">
          {/* 高知県西部 */}
          <TableScope className="table table-bordered text-center">
            <thead>
              <tr>
                <Th colSpan="2">会場<br /><SmallText>会場名をクリックすることで詳細を確認できます。</SmallText></Th>
                <Th style={{ width: '20%' }}>予約</Th>
              </tr>
            </thead>
            <tbody>
              {listHallWest}
            </tbody>
          </TableScope>
        </Tab>
        <Tab eventKey="home" title="高知市" >
          {/* 高知市内 */}
          <TableScope className="table table-bordered text-center">
            <thead>
              <tr>
                <Th colSpan="2">会場<br /><SmallText>会場名をクリックすることで詳細を確認できます。</SmallText></Th>
                <Th style={{ width: '20%' }}>予約</Th>
              </tr>
            </thead>
            <tbody>
              {listHallCity}
            </tbody>
          </TableScope>
        </Tab>
        <Tab eventKey="profile" title="東部">
          {/* 高知県東部 */}
          <TableScope className="table table-bordered text-center">
            <thead>
              <tr>
                <Th colSpan="2">会場<br /><SmallText>会場名をクリックすることで詳細を確認できます。</SmallText></Th>
                <Th style={{ width: '20%' }}>予約</Th>
              </tr>
            </thead>
            <tbody>
              {listHallEast}
            </tbody>
          </TableScope>
        </Tab>
      </Tabs>
    </>
  );
};

export default HallTable;
