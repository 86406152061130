import React from 'react';
import styled from 'styled-components';

const Title = styled.h2`
    color: #009d80;
    text-align: center;
    margin-bottom: 20px;
`;

const TrColor =styled.tr`
    color: #00ad80;
`;
const TdColor =styled.td`
    color: #00ad80;
`;
const Table = () => {   
    return (
        <>
            <Title>令和6年度　公立高校入試A日程での競争倍率</Title>
            <table className="table table-border text-center">
                <thead>
                    <tr><th>学校名</th><th>競争倍率</th><th>不合格者数</th></tr>
                </thead>
                <tbody>
                
                <tr>
                <TdColor>高知農業（生総）</TdColor><TdColor>1.38倍</TdColor><td>15名</td>
                </tr>

                <TrColor>
                    <td>高知商業（総ﾏﾈ）</td><td >1.37倍</td><td >52名</td>
                </TrColor>
                <tr>
                    <td>高知工業(機械)</td><td >1.33倍</td><td >13名</td>
                </tr>

                <tr>
                <td>高知工業(建築)</td><td>1.33倍</td><td>13名</td>
                </tr>

                <tr>
                <td >高知工業（情技）</td><td >1.30倍</td><td>12名</td>
                </tr>

                <tr>
                <td>高知国際（普通）</td><td>1.25倍</td><TdColor>48名</TdColor>
                </tr>


                </tbody>
            </table>
        </>
    );
}

export default Table;