import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MediaQuery from "react-responsive";
import {device} from '../../MainContents/Style/StyleDevice'

const Heading= styled.h2`
    margin-top: 30px;
    margin-bottom: 30px;
    font-weight: bold;
    @media ${device.laptop}{
        font-size: 1.5rem;
    }
`;


const CardBackOdd = styled.div`
    font-weight: bold;
    font-size: 20px;
    backgroud: #fdfdfd;
    padding-top: 50px;
    padding-bottom: 90px;
    margin-bottom: 30px;
    background:#FBFBFB;
    @media ${device.laptop}{
        font-size: 16px;
    }
`;
const CardBackEven = styled.div`
    font-weight: bold;
    font-size: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
    @media ${device.laptop}{
        font-size: 18px;
    }
`;

const Necessary= styled.h4`
    border-bottom: 2px solid #00ad80;
    max-width: 200px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
`;




const NecessaryLink = styled.a`
color: #00ad80;
font-size: 25px;
margin-top:40px;
    @media ${device.laptop}{
        font-size: 20px;
    }
`;
const NecessaryLinkP = styled.p`
color: #00ad80;
font-size: 25px;

`;

const MarginWid = styled.div`
    backgroud: #fdfdfd;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top:50px;
    margin-bottom: 50px;
`;



const Event = () => {
  const url ='/toitu/';
  const SvgUrl = 'http://www.w3.org/2000/svg';
  const SvgD = 'M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z';
    return (
        <>
            <div className="card center mt-5 border-0" id="description">
                <div className="card-body text-center" >
                    <Heading className="card-title" id="process">統一模試　申込み～登録</Heading>
                    
                    
                    <CardBackOdd>
                  　    <h3 id="STEP1">STEP1.受験会場が予約制かどうかを確認</h3>
                  　        <NecessaryLink >
                                <Link  to={url + 'Hall'} className="nav-scope" style={{color: '#00ad80'}}>
                                    <NecessaryLinkP>
                                        公開会場案内で確認&ensp;<svg xmlns={SvgUrl} width="20" height="20" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d={SvgD}/></svg>
                                    </NecessaryLinkP>
                                </Link>
                            </NecessaryLink>
                        <div className="row g-0" >
                                <div className="col-md-7" >
                                    <Necessary>予約が必要な場合</Necessary>
                                    <a href="#STEP2" style={{color: 'black'}}>STEP2.</a>へお進みください。
                                    <MediaQuery query="(max-width: 768px)">
                                        <hr />
                                    </MediaQuery>
                                </div>
                                <div className="col-md-5">
                                    <div className="card-body">
                                        <Necessary style={{marginTop:'0'}}>予約がいらない</Necessary>
                                         予約制でない場合は直接会場にお越し下さい。
                                    </div>
                                </div>
                        </div>       
                    </CardBackOdd>
                   
                    <CardBackEven id="STEP2">
                  　    <h3 style={{marginBottom:'30px'}}>STEP2.受付ページにて受験者の情報を登録</h3>
                        登録完了後にWeb登録画面・メールにて<span style={{color: '#00ad80'}}>予約番号</span>をお知らせいたします。<br />　
                        <div className="mt-3">
                            <NecessaryLink href="https://tosajuku.info/tsjk_webpage/reservation/receptionReservation/index.html"  style={{color: '#00ad80'}}>
                                        受付ページはこちら&ensp;<svg xmlns={SvgUrl} width="20" height="20" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d={SvgD}/></svg>
                            </NecessaryLink>
                        </div>
                    </CardBackEven>
                    <MarginWid>
                    <CardBackOdd>
                  　    <h3 style={{marginBottom:'30px'}}>STEP3.試験日当日、各会場で予約番号をお見せください。</h3>
                        <p>※予約番号はその回に限り有効です。</p>
                        なお、予約のキャンセルにつきましてはWeb画面からキャンセルすることが可能です。<br />
                        キャンセル：所要時間１分程度<br />
                        <div className="mt-5">
                            <NecessaryLink href="https://tosajuku.info/tsjk_webpage/reservation/alteration/index.html" style={{color: '#00ad80'}}>
                            キャンセルはこちら&ensp;<svg xmlns={SvgUrl} width="20" height="20" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d={SvgD}/></svg>
                            </NecessaryLink>
                        </div>
                    </CardBackOdd>
                    </MarginWid>
                </div>
            </div>
        </>
    );
}

export default Event;