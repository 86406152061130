import React from 'react';
import AnswerField from './AnswerField'
import ScrollToTopOnMount from '../MainContents/AutoScrollTop'; 
import {device} from '../MainContents/Style/StyleDevice';
import { IoIosArrowRoundDown} from "react-icons/io";
import styled from 'styled-components';

// クッリクするたびにレンダリングは動作してしまうのでコメントアウト
// import ScrollToTopOnMount from '../MainContents/AutoScrollTop'; 


const MarginTop = styled.div`;
    margin-top:140px;
    margin-bottom: 30px;
`;

const MarginWid =styled.div`
    margin:auto;
    width: 95%;
    margin-top:30px;
`;

const ButtonScope =styled.button`
    margin-left: 10px;
    margin-top: 10px;
    padding: 10px;
    border: 0;
    border-bottom: 1px solid #00ad80;
    background-color: white;
    text-align: left;
`;
const QuestionTitle = styled.h1`
    color: #00ad80;
    font-weight: bold;
    letter-spacing: 20px;
    @media ${device.laptop}{
        letter-spacing: 0px;
    }
`;


class Greeting extends React.Component {
    constructor (props) {
        super(props)
        this.state = {showFlag : true}
        this.state = {showFlag2 : true}
        this.state = {showFlag3 : true}
        this.state = {showFlag4 : true}
        this.state = {showFlag5 : true}
        this.state = {showFlag6 : true}
        this.state = {showFlag7 : true}
        this.state = {showFlag8 : true}
        this.state = {showFlag9 : false}
        this.state = {showFlag10 : false}
        this.state = {showFlag11 : false}
        this.state = {showFlag12 : false}
    }
    
    render () {
        const {showFlag,showFlag2,showFlag3,showFlag4,showFlag5,showFlag6,showFlag7,showFlag8,showFlag9,showFlag10,showFlag11,showFlag12 } = this.state;

        let text1 = showFlag  ?  <AnswerField id="1"/> : '';
        let text2 = showFlag2 ? <AnswerField id="2"/> : '';
        let text3 = showFlag3 ? <AnswerField id="3"/> : '';
        let text4 = showFlag4 ? <AnswerField id="4"/> : '';
        let text5 = showFlag5 ? <AnswerField id="5"/> : '';
        let text6 = showFlag6 ? <AnswerField id="6"/> : '';
        let text7 = showFlag7 ? <AnswerField id="7"/> : '';
        let text8 = showFlag8 ? <AnswerField id="8"/> : '';
        let text9 = showFlag9 ? <AnswerField id="9"/> : '';
        let text10 = showFlag10 ? <AnswerField id="10"/> : '';
        let text11 = showFlag11 ? <AnswerField id="11"/> : '';
        let text12 = showFlag12 ? <AnswerField id="12"/> : '';

        return (
            <MarginTop >
                <ScrollToTopOnMount />
                {/* 9つの質問を掲載 */}
                <div className="text-center">
                    <QuestionTitle>よくある質問と回答</QuestionTitle>
                </div>

                <MarginWid>
                <div class="row row-cols-1 row-cols-md-2 g-4">
                    <div class="col">
                            <div class="card">
                                <div class="card-body">
                                <ButtonScope className="btn-scope-design" onClick={() => {this.setState({showFlag : !this.state.showFlag})}}>
                                    Ｑ.事前の申込みは必要ですか？<IoIosArrowRoundDown />
                                </ButtonScope><br />
                                {text1}
                                </div>
                            </div>
                    </div>
                    <div class="col">
                            <div class="card">
                                <div class="card-body">
                                <ButtonScope className="btn-scope-design" onClick={() => {this.setState({showFlag2 : !this.state.showFlag2})}}>
                                    Ｑ.統一予約のキャンセル・会場変更をしたい方？<IoIosArrowRoundDown />
                                </ButtonScope><br />
                                {text2}
                                </div>
                            </div>
                    </div>
                    <div class="col">
                            <div class="card">
                                <div class="card-body">
                                <ButtonScope className="btn-scope-design" onClick={() => {this.setState({showFlag3 : !this.state.showFlag3})}}>
                                    Ｑ.予約番号はどうすればいいですか？？<IoIosArrowRoundDown />
                                </ButtonScope><br />
                                {text3}
                                </div>
                            </div>
                    </div>
                    <div class="col">
                            <div class="card">
                                <div class="card-body">
                                <ButtonScope className="btn-scope-design" onClick={() => {this.setState({showFlag4 : !this.state.showFlag4})}}>
                                    Ｑ.メールが届かない場合は？<IoIosArrowRoundDown />
                                </ButtonScope><br />
                                {text4}
                                </div>
                            </div>
                    </div>
                    <div class="col">
                            <div class="card">
                                <div class="card-body">
                                <ButtonScope className="btn-scope-design" onClick={() => {this.setState({showFlag5 : !this.state.showFlag5})}}>
                                    Ｑ.最寄りの会場がわからない？<IoIosArrowRoundDown />
                                </ButtonScope><br />
                                {text5}
                                </div>
                            </div>
                    </div>
                    <div class="col">
                            <div class="card">
                                <div class="card-body">
                                <ButtonScope className="btn-scope-design" onClick={() => {this.setState({showFlag6 : !this.state.showFlag6})}}>
                                    Ｑ.持参するものは？<IoIosArrowRoundDown />
                                </ButtonScope><br />
                                {text6}
                                </div>
                            </div>
                    </div>
                    <div class="col">
                            <div class="card">
                                <div class="card-body">
                                <ButtonScope className="btn-scope-design" onClick={() => {this.setState({showFlag7 : !this.state.showFlag7})}}>
                                    Ｑ.どんな服装でテストを受けたらいいの？<IoIosArrowRoundDown />
                                </ButtonScope><br />
                                {text7}
                                </div>
                            </div>
                    </div>
                    {/* <div class="col">
                            <div class="card">
                                <div class="card-body">
                                <ButtonScope className="btn-scope-design" onClick={() => {this.setState({showFlag8 : !this.state.showFlag8})}}>
                                    Ｑ.テストの出題範囲は?<IoIosArrowRoundDown />
                                </ButtonScope><br /> */}
                                {/* <p>中３の学習進度を考慮しながら、中１・中２の復習が順次加わっていきます</p>
                                <p>年間６回で中学３年間分の内容を復習できるようになっています。</p> */}
                                {/* <a href="https://www.tosajuku.com/toitu_test_Scope.pdf" target="_blank" style={{color:'black'}}>{text8}</a>                            
                                </div>
                            </div>
                    </div> */}
                    <div class="col">
                            <div class="card">
                                <div class="card-body">
                                <ButtonScope className="btn-scope-design" onClick={() => {this.setState({showFlag9 : !this.state.showFlag9})}}>
                                    Ｑ.テストはいつ返送されますか？<IoIosArrowRoundDown />
                                </ButtonScope><br />
                                {text9}
                                </div>
                            </div>
                    </div>
                    <div class="col">
                            <div class="card">
                                <div class="card-body">
                                <ButtonScope className="btn-scope-design" onClick={() => {this.setState({showFlag10 : !this.state.showFlag10})}}>
                                    Ｑ.志望校を変えて判定してみたい。<IoIosArrowRoundDown />
                                </ButtonScope><br />
                                {text10}
                                </div>
                            </div>
                    </div>
                    <div class="col">
                            <div class="card">
                                <div class="card-body">
                                <ButtonScope className="btn-scope-design" onClick={() => {this.setState({showFlag11 : !this.state.showFlag11})}}>
                                    Ｑ.テストが返却されない。<IoIosArrowRoundDown />
                                </ButtonScope><br />
                                {text11}
                                </div>
                            </div>
                    </div>
                    <div class="col">
                            <div class="card">
                                <div class="card-body">
                                <ButtonScope className="btn-scope-design" onClick={() => {this.setState({showFlag12 : !this.state.showFlag12})}}>
                                    Ｑ.採点の間違いがあった。<IoIosArrowRoundDown />
                                </ButtonScope><br />
                                {text12}
                                </div>
                            </div>
                    </div>
                </div>
                </MarginWid>
            </MarginTop>
        )
    }
}

export default Greeting;
