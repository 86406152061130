import Timetable  from './Timetable.jsx';
import ScheduleTable from './ScheduleTable.jsx';
import ScrollToTopOnMount from '../MainContents/AutoScrollTop'; 
import styled from 'styled-components';
import PageTitle from '../MainContents/PageTitle/PageTitle.js'


const MarginTop = styled.div`
    margin:0 auto;
    width: 90%;
    margin-top: 100px;
    
`;
const Heading = styled.h2`
    margin-bottom: 30px;
    padding: 15px;
    font-size: 24px;
    font-size: 1.5rem;
    background-color:#009d80;
    color: #fff;
    border-radius: 7px;
`;


const Schedule = () => {
    return(
        <MarginTop >
            <ScrollToTopOnMount />
            
            <PageTitle title={'日程・時間割・受験料'} englishText={'Schedule&Timetable&Price'} />

            <Heading>2024年度　統一模試実施日</Heading>
            <ScheduleTable />

            <Heading>模擬テスト・時間割・範囲表</Heading>
            <Timetable />
        </MarginTop>
    );

}

export default Schedule;
