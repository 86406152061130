import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterBackColor = styled.footer` background-color: #f5f5f5`;
const BackColor = styled.div` background-color: #00ad80`;
const ProtectionPolicy = styled.p`
    text-align-last: right;
    font-size: 12px;
    margin-bottom: 3px;
`;

const Title = styled.p`
    font-size: 16px;
    margin-bottom: -3px;
`;
const TitleSecond = styled.p`
    font-size: 16px;
    margin-bottom: -2px;
`;

// フッターを作成する
const Footer = () => {
    const SvgUrl = 'http://www.w3.org/2000/svg';
    const SvgD = 'M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z';
    const url = '/toitu/';
    return (
        <>
            <section className="">

                <FooterBackColor className="text-center text-black">

                    <div className="container p-4 pb-0">

                        <section className="">
                            <ProtectionPolicy >
                                <Link to={url + 'ProtectionPolicy'} style={{ color: 'black' }}>
                                    個人情報保護方針<svg xmlns={SvgUrl} width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d={SvgD} /></svg>
                                </Link>
                            </ProtectionPolicy>

                            <ProtectionPolicy >
                                <a href="https://toitumosi.jp/index21.html" style={{ color: 'black' }}>
                                    統一模試事務局&ensp;連絡ページ<svg xmlns={SvgUrl} width="10" height="10" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d={SvgD} /></svg>
                                </a>
                            </ProtectionPolicy>
                            <Title className="d-flex justify-content-center align-items-center">
                                <p>高知県統一模試実施研究会</p>
                            </Title>
                            <TitleSecond className="d-flex justify-content-center align-items-center">
                                <p>〒780-0822 高知市はりまや町3-9-5</p>
                            </TitleSecond>
                            <TitleSecond className="d-flex justify-content-center align-items-center" style={{ marginBottom: '0px' }}>
                                <p>TEL:(088)885-3470</p>
                            </TitleSecond>
                            <TitleSecond className="d-flex justify-content-center align-items-center">
                                <span>平日:月～金 9：00～17：00</span>
                            </TitleSecond>
                        </section>

                    </div>

                    <BackColor className="text-center p-3">
                        Copyright c 2021 Toitumosi corporation.All Rights Reserved.
                    </BackColor>

                </FooterBackColor>

            </section>
        </>
    );
};

export default Footer;