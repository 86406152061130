import React,{useState} from 'react';
import styled from 'styled-components';
import AudioPlayer from './AudioPlayer.jsx';

const AudioDiv = styled.div`
    width: 100%;
    height: 40vh;
    object-fit: cover;
    margin-left: 100px;
`;
const Arrow = styled.span`
    background: #fff;
    border-radius: 29px;
    padding: 0px 4px;
    color: #2cc12c;
    font-size: 17px;
    margin-left: 3px;
`;
const BookSubScope = styled.div`
    width: 100%;
    background-color: #fff;
    height: 50vh;
    margin-left: 100px;
    padding: 20px;
    
`;

const Button = styled.button`
 margin-left:10%;
 border-radius: 3px;

 background: #00ad80;
 color: #fff;
 margin-top:10px;
 width: 35%;
 padding: 7px;

`;




const AudioList = (props) => {
    const [audio, setAudio] = useState(0);
    const ThaWhatTimes = (props) => {
        return (
            <div>
                <Button onClick={() => setAudio(props.num)}>&ensp;第{props.num}回&ensp;</Button>
            </div>
        );
    }
    return (
        <>
             {props.viewTrue!==undefined &&
            <BookSubScope className="card mb-3 border-0"> 
                <div className="row">
                    <div className="col-sm-6" >
                        <ThaWhatTimes num={'1'} />
                        <ThaWhatTimes num={'2'} />
                        <ThaWhatTimes num={'3'} />
                        <ThaWhatTimes num={'4'} />
                        <ThaWhatTimes num={'5'} />
                        {/* <ThaWhatTimes num={'6'} /> */}
                    </div>

                    <div className=" col-sm-6 text-center mt-2" style={{top: '20%'}}>
                        <AudioPlayer playNumber={audio===0 ? false : audio} />
                    </div>
                </div> 
               
            </BookSubScope>
            //  <audio src={sam1} preload="none" controls="controls" controlsList={NoDownload}></audio>
             }
              
        </>
    );
}

export default AudioList;