import React from 'react'

export default class AutoScrollTop extends React.Component {
    componentDidMount(prevProps) {
      window.scrollTo(0, 0)
    }
  
    render() {
      return null
    }
  }