import React from 'react';
import styled from 'styled-components';
import AudioPlayer from './AudioPlayer.jsx';
import AudioPlayerYear from './AudioPlayerYear.jsx';

const Title = styled.h5`
    color: #009d80;
    text-align: center;
`;
const SubBack = styled.div`
    background-color: #f5f5f5;

`;
const SubTextLi = styled.li`
    margin-top: 3px;
    list-style-type: none; 
    position: relative; 
    padding-left: 0.6em;
    &:before {
        border-radius: 50%; /*丸くする*/
        width: 5px; /*点の幅*/
        height: 5px; /*点の高さ*/
        display: block;
        position: absolute; /*絶対配置*/
        left: 0; /*点の位置*/
        top: 0.6em; /*点の位置*/
        content: "";
        background: #009d80; /*点の色*/
    }
`;
const FlowText = styled.p`
    text-decoration: none;
    padding-bottom: 3px; /* 文字と下線に隙間を作る */
    color: #000;
    display: block;
    margin-top: 5px;
    &:after {
        content: '';
        width: 0;
        transition: all 0.3s ease;
        border-bottom: 1px solid #000;
        display: block;
    }
    &:hover:after {
        width: 70%;
        border-bottom: 1px solid #000;
    }
`;
const Button = styled.button`
 border-radius: 3px;
 background: #00ad80;
 color: #fff;
 margin-top:10px;
 padding: 7px;

`;


const BookIntroduction = () => {
    return (
        <>
        
            <SubBack className="card center mt-5 border-0" >
                <div className="card-body ">
                    <Title className="card-title">ー&ensp;2023年度用　高知県公立高校入試　統一模試&ensp;ー</Title>
                    <p>統一模試で昨年実施した5回分の問題に解答と詳しい解説を付けた問題集。
                       県下有名書店で好評発売中！
                    </p>
                    <small>統一模試問題集(1,430円・税込)</small>
                     <AudioPlayer playNumber={'1'} />
                     <AudioPlayer playNumber={'2'} />                     
                     <AudioPlayer playNumber={'3'} />
                     <AudioPlayer playNumber={'4'} />                     
                     <AudioPlayer playNumber={'5'} />
                     {/* <AudioPlayer playNumber={'6'} />                      */}
                </div>
            </SubBack>
                <div style={{marginTop: '30%'}}></div>    
        
    
                <Title className="card-title">ー&ensp;高知県公立高校入試問題&ensp;ー</Title>
                <Title className="card-title"><small>高知県教育委員会</small></Title>
                <p style={{fontSize:'13px'}}>
                    2010年～2023年英語リスニング音声を聞くことができます。
                </p>    
                <AudioPlayerYear playNumber={'23'} />
                <AudioPlayerYear playNumber={'22'} />
                <AudioPlayerYear playNumber={'21'} />
                <AudioPlayerYear playNumber={'20'} />
                <AudioPlayerYear playNumber={'19'} />                     
                <AudioPlayerYear playNumber={'18'} />
                <AudioPlayerYear playNumber={'17'} />                     
                <AudioPlayerYear playNumber={'16'} />
                <AudioPlayerYear playNumber={'15'} />     
                <AudioPlayerYear playNumber={'14'} />     
                <AudioPlayerYear playNumber={'13'} />     
                <AudioPlayerYear playNumber={'12'} />     
                <AudioPlayerYear playNumber={'11'} />     
                <AudioPlayerYear playNumber={'10'} />     
                <div style={{marginTop: '30%'}}></div>    
        </>
    );
}

export default BookIntroduction;