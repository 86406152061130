import React from 'react';

import styled from 'styled-components';

// スタイル宣言
const TableScope = styled.table`
  width: 90%;
  margin: auto;
  table-layout: fixed;
  word-wrap: break-word;
  overflow-x: auto;
  display: table;
  margin-bottom: 10px;
`;

const ExaminationTable = props => {
    return (
    <>
        <table className="table table-border">
            <tr>
                <td>出願期間</td><td>{props.applicationPeriod}</td>
            </tr>
            <tr>
                <td>変更期間</td><td>{props.changePeriod}</td>
            </tr>
            <tr>
                <td>検査日</td><td>{props.searchDate}</td>
            </tr>
            <tr>
                <td>合格発表</td><td>{props.passingAnnouncement}</td>
            </tr>
            <tr>
                <td>検査内容</td><td>{props.testingDetail}</td>
            </tr>
            <tr>
                <td colSpan="2">{props.recruitingCapacity}</td>
            </tr>
        </table>
    </>
    );
};

export default ExaminationTable;