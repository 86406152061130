import React from "react";
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const UlDelete = styled.ul`
    list-style: none;
`;

const LiColor = styled.li`
    color: #009d80;
    font-size:20px;
    font-weight: bold;
`;
const TextColor = styled.td`color: #009d80;`;

const Timetable = () => {
    const SvgUrl = 'https://www.w3.org/2000/svg';
    const SvgD = 'M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z';
    return (
    <>
    <div className="">
        <p>会場により開始時間が異なる場合ございます。</p>
        <p><Link to={'/toitu/Hall'}>公開会場案内</Link>ページよりご確認ください。</p>
    </div>
    <table className="table table-bordered text-center">
        <tbody>
            <tr>
                <td colSpan="2">8：45までに入室</td>
            </tr>
            <tr>
                <td>9：00～ 9：45</td><td>国語(45分)</td>
            </tr>
            <tr>
                <td>9：50～10：35</td><td>数学(45分)</td>
            </tr>
            <tr>
                <td>10：40～11：25</td><td>英語(45分)</td>
            </tr>
            <tr>
                 <td>11：30～12：30</td><td>理科・社会(各30分)</td>
            </tr>
            <tr>
                <td colSpan="2">(オリジナルリスニングテスト実施)</td>
            </tr>
            <tr>
                <td colSpan="2">テスト志望校表紙回収 12:40退室</td>
            </tr>
            <tr>
                <TextColor colSpan="2">受験料　４,０００(税込み)</TextColor>
            </tr>
            <tr>
                <td colSpan="2">統一模試範囲表は
                    <a href="https://www.tosajuku.com/toitu_test_Scope.pdf" target="_blank" style={{color: '#00ad80'}}>こちら&ensp;<svg xmlns={SvgUrl} width="15" height="15" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d={SvgD}/></svg></a>
                </td>
            </tr>
        </tbody>
    </table>
    <UlDelete>
        <LiColor>当日は回収物の中に、住所・氏名・電話番号等の個人情報の記入が必須になります。</LiColor>
        <LiColor>会場付近は大変混み合います</LiColor>
        <LiColor>会場へのお車の乗り入れはお断りします。</LiColor>
    </UlDelete>
    </>
    );
}

export default Timetable;