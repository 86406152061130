import React from 'react';
import styled from 'styled-components';
import {R5No1, R5No2, R5No3, R5No4, R5No5} from './Sound/index';

const HeadingColor = styled.h3`
    color: #00ad80;
    font-weight: bold;
`;
const NoDownload = 'nodownload';

let audio ="";
const AudioPlayer = props => {
    // 値によって判断する
    switch (props.playNumber) {
        case '1':
            audio = R5No1;
            break;
        case '2':
            audio = R5No2;
            break;
        case '3':
            audio = R5No3;
            break;
        case '4':
            audio = R5No4;
            break;
        case '5':
            audio = R5No5;
            break;
        // case '6':
        //     audio = R5No6;
        //     break;
    
        default:
            break;
    }
    return (
        <>
        {
            props.playNumber!==false &&
            <>
            <HeadingColor>2023年&ensp;第{props.playNumber}回英語リスニング音声</HeadingColor>
            <audio src={audio} preload="none" controls="controls" controlsList={NoDownload}></audio>
            </>
        }  
        {
            props.playNumber===false &&
            <>
            <HeadingColor>2023年&ensp;第1回英語リスニング音声</HeadingColor>
            <audio src={R5No1} preload="none" controls="controls" controlsList={NoDownload}></audio>
            </>
        }  
        </>
    );
}

export default AudioPlayer;