import React,{useContext} from 'react';
import {UserCount } from './Hall'
import jsonCity from '../Json/HallInformation.json';
import jsonEast from '../Json/HallEastInformation.json';
import jsonWest from '../Json/HallWestInformation.json';
import styled from 'styled-components';

//　前提としてJSONファイルを読み込み各会場を表示する
// スタイル宣言
const TextColor = styled.th`
    color: #00ad80;
`;

const ThMiddle = styled.th`
    vertical-align: middle !important;
`;
const Tr = styled.tr`
    font-size: 20px;
`;

const HallDates = (props) => {
    const { hallId, setHallId } = useContext(UserCount);
    // const [ state, dispatch]  = useReducer(reducer, initalState);
    const numId = props.value;
    const jsonDateArr2 = props.division ==='west' ?  jsonWest.hallInfo[numId]: false; 

    const jsonDateArr = props.division ==='city' ? jsonCity.hallInfo[numId] : jsonEast.hallInfo[numId] ; 

    const loName = props.division;
    // 会場地区
    const id = jsonDateArr2===false ? jsonDateArr.id : jsonDateArr2.id;
    const idAndLocation = loName + id; 
   
    const area = jsonDateArr2===false ? jsonDateArr.area : jsonDateArr2.area; 

    // 会場名
    const hall = jsonDateArr2===false ? jsonDateArr.hall : jsonDateArr2.hall;

    // 会場先URL
    const hallUrl = jsonDateArr2===false ? jsonDateArr.hallUrl : jsonDateArr2.hallUrl;  

    // 会場が予約制かどうか
    const reserved = jsonDateArr2===false ? jsonDateArr.reserved : jsonDateArr2.reserved;

    /*  縦結合する値(範囲)  */
    //    2以上 ・・　結合
    //    1 ・・　複数の会場があることを示す
    //    undefined　・・　地区内で 1つの会場   
    const rowspan = jsonDateArr2===false ? jsonDateArr.rowspan : jsonDateArr2.rowspan;

    return (
        <>
        
            <Tr>
                {/* 地区内で複数の会場がある場合　結合*/}
                {   rowspan > 1 && 
                    <>
                        <ThMiddle rowSpan={rowspan} >{area}</ThMiddle>
                        <th><a onClick={() => setHallId(idAndLocation)}>{hall}</a></th>
                        <TextColor>{reserved}</TextColor>
                    </>
                }

                {/* 地区内で複数の会場がある場合  */}
                {   rowspan===1 && 
                    <>
                        <th><a onClick={() => setHallId(idAndLocation)}>{hall}</a></th>
                        <TextColor>{reserved}</TextColor>
                    </>
                }

                {/* 地区内で　１つの会場のみ */}
                {   rowspan===undefined && 
                    <>
                        <th>{area}</th>
                        <th><a onClick={() => setHallId(idAndLocation)}>{hall}</a></th>
                        <TextColor>{reserved}</TextColor>
                    </>
                }
            </Tr>
        </>
    );
};

export default HallDates;