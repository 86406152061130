import React from 'react';
import styled from 'styled-components';

const Heading = styled.h2`
    margin-bottom: 30px;
    padding: 15px;
    font-size: 24px;
    font-size: 1.5rem;
    background-color:#009d80;
    color: #fff;
    border-radius: 7px;
`;

const Appeal = () => {
    return (
        <>
           <Heading>自分の『行きたい高校』を受験できるチャンスは1回のみ！</Heading>
           <p className='fw-bold border-bottom'>
            <span className='text-danger'>重要</span>&nbsp;&nbsp;来年度の高校入試から学校・学科の定員変更が実施される可能性があります。
            受験校の決定に大きな影響が考えられます。県教委からの今後の情報に注意を！
           </p>
           <p>「自分の進学したい高校」へはみんなも行きたい！</p>
           <ul>
               <li>①A日程入試だけで定員が確保された学校・学科ではB日程での募集が行われない。</li>
               <li>②志願者が増えれば不合格者はもちろん増える。しかし、定員に満たない場合でも不合格者が出る。</li>
               <li>全くあたり前のことだが、合格しないと入学させてくれない。</li>
               <li>受験生に人気のある学校は毎年高い競争率になっている。 高校入試選抜は恐ろしく厳しい！</li>
           </ul>
        </>
    );
}
export default Appeal;