import Appeal from './Appeal.jsx';
import Table from './Table.jsx';
import Comment from './Comment';
import Advice from './Advice';
import MailIntroduction from './MailIntroduction';
import ScrollToTopOnMount from '../MainContents/AutoScrollTop'; 
import styled from 'styled-components';
import PageTitle from '../MainContents/PageTitle/PageTitle.js'

const MarginTop = styled.div`
    margin:0 auto;
    width: 90%;
    margin-top: 100px;
`;

const ForToNextYear = () => {
    return(
        <MarginTop>
            <ScrollToTopOnMount />
            <PageTitle title={'本番入試に向けて'} englishText={'ForExamination'} />
            <Appeal />
            <Table />
            <Comment />
            <Advice />
            <MailIntroduction />
        </MarginTop>
    );
}

export default ForToNextYear;