import React from 'react';
import jsonCity from '../Json/HallInformation.json';
import jsonEast from '../Json/HallEastInformation.json';
import jsonWest from '../Json/HallWestInformation.json';
import MapImport from './MapImport.jsx';

const Map = (props) => {
    const id = props.HALLID;

    // 数字だけ格納
    const numOnly = id.replace(/[^0-9]/g, '');
    // 文字列だけ出力
    const textOnly = id.substr( 0, 4 );

    // 配列は0から開始しないといけない
    const locationCity = textOnly ==='city' ?  jsonCity.hallInfo[numOnly-1] : false; 
    const locationOuter = textOnly ==='east' ?  jsonEast.hallInfo[numOnly-1] : jsonWest.hallInfo[numOnly-1];  
    
    return (
        <> 
        {
            locationCity && 
            <MapImport 
                hallName={locationCity.hall}
                hallURL={locationCity.hallUrl}
                Reserved={locationCity.reserved}
                Detail={locationCity.detail}
                Detail2={locationCity.detail2}
                Detail3={locationCity.detail3}
                Detail4={locationCity.detail4}
            />
        }
        {
          (() => {
            if(locationCity===false && textOnly==='east'){
                return <MapImport 
                hallName={locationOuter.hall}
                hallURL={locationOuter.hallUrl}
                Reserved={locationOuter.reserved}
                Detail={locationOuter.detail}
            />
            } 
          })()
        }    
        {
          (() => {
            if(locationCity===false && textOnly==='west'){
                return <MapImport 
                hallName={locationOuter.hall}
                hallURL={locationOuter.hallUrl}
                Reserved={locationOuter.reserved}
                Detail={locationOuter.detail}
                Detail2={locationOuter.detail2}
                Detail3={locationOuter.detail3}
                Detail4={locationOuter.detail4}
            />
            } 
          })()
        }    
        </>
    );
}

export default Map;