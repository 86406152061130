import SampleView from "./SampleView.jsx";
import Img2 from "../../../img/seiseki1.png";
import Img3 from "../../../img/seiseki2.png";
import Img4 from "../../../img/seiseki3.png";
import Img5 from "../../../img/seiseki4.png";
import Img6 from "../../../img/seiseki5.png";
import Img7 from "../../../img/seiseki6.png";
import styled from "styled-components";

const Heading = styled.h2`
    margin-bottom: 30px;
    padding: 15px;
    font-size: 24px;
    font-size: 1.5rem;
    background-color:#009d80;
    color: #fff;
    border-radius: 7px;
`;
const MarginTop = styled.div`
    width: 90%;
    margin:0 auto;
    margin-bottom: 30px;
    margin-top: 100px;
`;

const Sample =  () => {
    return (
        <MarginTop>
            <Heading>WEB成績判定サンプル</Heading>
            <SampleView 
                title={'成績表1'}
                text={'第1回から第6回までの、点数と評価をわかりやすく一つで表示し、成績の変化をわかりやすく確認出来ます。'}
                imgSample={Img2}
            />
            <SampleView 
                title={'成績表2'}
                text={'1回ごとの成績を、点数・平均点・偏差値で細かく表示します。'}
                imgSample={Img3}
            />
            <SampleView 
                title={'成績チャート'}
                text={'成績チャートにより、各教科・5教科合計の偏差値をわかりやすくレーダーチャートで表示します。'}
                text2={'また、その前に受験された偏差値も表示し視覚的に比較できます。受験された回の偏差値も表示し視覚的に比較できるようになっています。'}
                imgSample={Img4}
            />
            <SampleView 
                title={'全体分布'}
                text={'全体分布によって、各教科(英･数･国･理･社)の学力が全体の中で、どこに位置するかをグラフで分かりやすく表示しています。'}
                imgSample={Img5}
            />
            <SampleView 
                title={'成績比較チャート'}
                text={'◎のボックスから、比較したい高校･学科を選択すると、選択した高校･学科の志望者の平均点と自分の点数をレーダーチャートで合わせて表示するので、視覚的にわかりやすく比較できます。'}
                imgSample={Img6}
            />
            <SampleView 
                title={'判定'}
                text={'◎のボックスで選択した学校･学科の簡易判定をします。Ａ･Ｂ･Ｃ･Ｄで判定結果を表示します。'}
                text2={'短評では、上の判定まであと何点必要なのかなどを表示したりします。'}
                imgSample={Img7}
            />
        </MarginTop>
    );
}

export default Sample;