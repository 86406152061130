import React,{useState} from 'react';
import HallTable from './HallTable.jsx';
import MapView from './Map.jsx';
import {device} from '../MainContents/Style/StyleDevice'
import styled from 'styled-components';
import ScrollToTopOnMount from '../MainContents/AutoScrollTop'; 
import PageTitle from '../MainContents/PageTitle/PageTitle.js'
export const UserCount = React.createContext();
export const MapLacation = React.createContext();

// スタイル宣言
const MarginTop = styled.div`
    margin:0px auto;
    margin-top: 120px;
    width:80%;
    @media ${device.laptop}{
      width:100%;
   }
`;
const TableScope = styled.table`
  width: 90%;
  margin: auto;
  table-layout: fixed;
  word-wrap: break-word;
  overflow-x: auto;
  display: table;
  margin-bottom: 10px;
`;

const LiColorRed = styled.li`
  color: #00ad80;
`;
const SpanColorRed = styled.span`
  color: #00ad80;
`;

const Th = styled.th`
    padding: 10px;
`;
const Button = styled.button`
    padding: 10px;
    color: #fff;
    background: #00ad80;
    margin-bottom: 50px;
    font-size: 25px;
    border-radius: 3px;
    font-weight: bold;
    margin-top: 30px;
`;
const Heading = styled.h2`
margin-bottom: 30px;
    padding: 15px;
    font-size: 24px;
    font-size: 1.5rem;
    background-color: #009d80;
    color: #fff;
    border-radius: 7px;
`;
const SpMargin = styled.div`
  @media ${device.laptop}{
    width:90%;
    margin:auto;
  }
`;
const Hall = () => {
  const [hallId, setHallId] = useState(0);
  const value = {
    hallId,
    setHallId,
  };
  // console.log('メインページstate確認', hallId);
  // console.log('メインページstate確認2', state.location);


  const SvgUrl = 'http://www.w3.org/2000/svg';
  const SvgD = 'M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z';
  return(
    <>
    <MarginTop>
      <ScrollToTopOnMount />
      <SpMargin>
        <PageTitle title={'公開会場案内'} englishText={'PublicHallGuidance'} />
        <Heading>会場の予約制を確認</Heading>
        <ul>
          <li>会場名をクリック・タップすることで予約状況なども確認できます。</li>
          <li>予約制でない場合は直接会場にお越し下さい。</li>
          <li>※<SpanColorRed>予</SpanColorRed>・・・予約が必要な会場となります。予約がない場合は受験をできないことがあります。</li>
          <LiColorRed>※  会場付近が大変混雑しますので、車でのご来場はお断りします。</LiColorRed>
          <li>※地区は、目安になります。どの会場でもご利用できます。</li>
          <li>※今年は南国会場（JA高知　土長地区本部）が改築中の為、利用できません。</li>
          <li>・近隣の会場「土佐塾山田教室・土佐塾南国教室・土佐塾高須教室・土佐学舎」にて、受験のご協力をお願い致します。</li>
          
        </ul>
      </SpMargin>
      <div className="text-center">
        <Button><a style={{color: '#fff'}} href="https://tosajuku.info/tsjk_webpage/reservation/receptionReservation/index.html">会場予約はこちら&ensp;<svg xmlns={SvgUrl} width="20" height="20" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d={SvgD}/></svg></a></Button>
        {/* <Button><a style={{color: '#fff'}}>会場予約はこちら(8/26～)&ensp;<svg xmlns={SvgUrl} width="20" height="20" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d={SvgD}/></svg></a></Button> */}
      </div>
    <UserCount.Provider value={value}>
      <HallTable />
    </UserCount.Provider>
    </MarginTop>

    { hallId !==0 && <MapView HALLID={hallId} />}

    </>
  );
};

export default Hall;