import React from 'react';
import styled from 'styled-components';
const MarginTop = styled.div`
    margin:0 auto;
    width: 90%;
    margin-top: 100px;
`;
const Button = styled.button`
    padding: 10px;
    color: #fff;
    background: #00ad80;
    margin-bottom: 50px;
    font-size: 25px;
    border-radius: 3px;
    font-weight: bold;
`;

const MailIntroduction = () => {
    const SvgUrl = 'http://www.w3.org/2000/svg';
    const SvgD = 'M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z';
    return (
        <MarginTop>
            <div className="text-center">
                <Button><a href="https://toitumosi.jp/join/registmail.html" target="_blank" style={{color:'#fff'}}>お知らせメール登録はこちら</a>&ensp;<svg xmlns={SvgUrl} width="20" height="20" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d={SvgD}/></svg></Button>
            </div>
        </MarginTop>
    );
}

export default MailIntroduction;