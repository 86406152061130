import React from 'react';
import toituLogo from '../../img/toitu.png';
// import toituLogo from '../../img/toitu2.png';
// import toituLogo from '../../img/toitu3.png';
import { Link } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import '../../css/Header/Header.scss';
import styled from 'styled-components';


// メディアクエリを使用して表示しようかと思ったがなかなかうまく動作しなかった
const Menu = styled.div`
    display: block !important;
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100%;
    background-color:#009d80;
    transition: width .2s;
`;

const buttonAlert = () => {
    let sampleElement = document.getElementById('spBtn');
    sampleElement.classList.remove('menu--isOpen');
  }

// モバイル用のハンバガーメニューとPCサイズのナビバーを作成
const NavbarsAndHanbargar = () => {
    const url ='/toitu/';
    return (
        <Nav className="mr-auto sp-Nav WidMax" style={{width: '100%'}}>
        <Link  to={url + 'Schedule'} className="Hover nav-scope paddingWhitespace" >
            <Nav>日程・時間割・受験料</Nav>
        </Link>

        <Link  to={url + 'Hall'} className="nav-scope">
            <Nav>公開会場案内</Nav>
        </Link>
        
        <Link  to={url + 'UserResultTable'}className="nav-scope">
            <Nav>個人成績表の見方</Nav>
        </Link>

        <Link  to={url + 'Examination'} className="nav-scope">
            <Nav>高校入試情報</Nav>
        </Link>
        
        <Link  to={url + 'ForToNextYear'} className="nav-scope">
            <Nav>本番入試に向けて</Nav>
        </Link>

        <Link  to={url + 'Question'} className="nav-scope">
            <Nav>よくある質問</Nav>
        </Link>
     

    </Nav>
    );
}

const Navbars = () => {
    const url ='/toitu/';
    return (
        <div className="fixed-top nav-mainColor">
            {/* ナビバー */}
            <Navbar collapseOnSelect expand="lg"  variant="dark">
              <Link to={url} >
                <Navbar.Brand >
                        <img
                            src={toituLogo}
                            alt="React Bootstrap logo"
                            style={{width: '70%'}}
                        />
                    </Navbar.Brand>
              </Link>
         
                {/* <Navbar.Toggle aria-controls="responsive-navbar-nav"  style={{backgroundImage:'linear-gradient(0deg, #990820 0%, #ba0020 100%)'}}/> */}
                {/* モバイルだけ表示するようにする */}
                <Menu id="spBtn" className="menu">
                    <button class="menu__button">
                        <span class="menu__lineTop"></span>
                        <span class="menu__lineMiddle"></span>
                        <span class="menu__lineBottom"></span>
                    </button>
                    <div  onClick={buttonAlert}>
                       <NavbarsAndHanbargar />
                    </div>
                </Menu>
               
                <Navbar.Collapse id="responsive-navbar-nav">
                    <NavbarsAndHanbargar />
                </Navbar.Collapse>
            </Navbar >
        </div>
       
    );
  };

export default Navbars;