import React from "react";

// 年間テスト
const testingDate =[
    '',
    '9月23日(月)',
    '10月20日(日)',
    '11月23日(土)',
    '12月15日(日)',
    '1月19日(日)'
]

const ScheduleTable  = () => {
    return (
        <>
            <table className="table table-bordered"> 
            <tbody>    
                {testingDate.map((date, i) =>
                    <> 
                    {   i!==0 && 
                            <tr >
                                <td key={i}>第{i}回</td>
                                <td >{date}</td>
                            </tr>
                    }
                    </>
                )}
            </tbody>
            </table>
        </>
    );
}

export default ScheduleTable;