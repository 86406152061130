import React from 'react';
import CommentCard from './ComentCard.jsx';
import img1 from '../../img/schoolDate1.png'
import img2 from '../../img/schoolDate2.png'
import img3 from '../../img/schoolDate3.png'
import styled from 'styled-components';
import {device} from '../MainContents/Style/StyleDevice'

const Heading = styled.h2`
    margin-bottom: 30px;
    padding: 15px;
    font-size: 24px;
    font-size: 1.5rem;
    background-color:#009d80;
    color: #fff;
    border-radius: 7px;
    margin-top: 50px;
`;

const MarginWid = styled.div`
    width: 80%;
    margin:0 auto;
    margin-bottom: 30px;
    border:2px solid #f5f5f5;
    border-radius: 3px;
    @media ${device.laptop}{
        width:100%;
     }
`;

const Messeage = styled.h4`color:rgb(0, 173, 128);`;
const Comment = () => {
    return(
        <>
        




            <p className="text">今年度の入試では昨年度の丸の内（普通）の競争率1.35倍を上回る高校が2校、1.30倍以上の競争率の高校が6校（上記：高知国際を除く5校と須崎総合：機械）にのぼった。</p>
            <p className="text">受験生の皆さんは、年間を通じて自分の志望校の動向は常に把握しておいてください。</p>
            <p className="text">ライバルが増加し倍率が上がっても「合格者」になるためには、本番入試までに実力をつけるしかない。</p>
            <p className="text">残された時間を悔いのないように過ごして欲しい。</p>
            
            <Heading>進路選択のポイントは</Heading>
            <ul>
                <li>①その学校・学科は、どんな内容をどのように学習するのか？</li>
                <li>②その学校を卒業したらどんな学校に進学したり、どんな資格をとったり、どんな企業に就職しているのか？高校で行われる学校説明会、入学案内パンフレット、高校のホームページや部活の先輩の話などまで参考にして、 まず、自分の将来から考えてみよう。 そして、合格するには、進学への意欲をしっかりと持ち、早い時期から勉強すればいいんです。 また、高校以降も困らない学力をつけることができるのも今だけだ。</li>
            </ul>
            <Heading>来年度の公立高校入試に向けては</Heading>
            <ul>
                <li>①人気のある学校・学科はやはり難しい。志望動向の変化は高知市周辺高校にも影響する。</li>
                <li>②入試問題が、思考力を問う問題、記述による解答を求める問題へとレベルアップしている。</li>
            </ul>
            <p className="text">
                受験生に人気のある学校・学科は、来春の入試も厳しいことは間違いないが、 郡部の志望者数の少ない学校・学科を受験するから、「自分にはあまり関係ない」と思う受験生もいるかもしれない。 しかし、入学志望者数の動向と定員との関係は大きく変化しないので、第一志望が厳しいと判断されると、 第二・第三志望へと受験校を変更し、地元の人気の高い順に難しくなる。
                一日も早く高校入試の現実を知り、意識を改め受験勉強に取り組み、志望する高校への合格をつかんでもらいたい。
            </p>

            <div className='mt-5 text-center'>
                <Messeage>高校入試は自分の実力を問われる真剣勝負です！</Messeage>
            </div>
            <Heading>「参考資料」　過去の統一模試志望者動向</Heading>
            <p>※受験者動向の大きく変化した3校（高知国際（普通）・高知商業（総マネ）・高知工業（建築）の統一模試志願者数の推移を表した。 
本番入試では難しくなると予想されていた。
</p>

            <CommentCard 
                title={'【高知国際(普通)】　（定員：200名）'}
                text={'定員200名に対し、249名の受験者、倍率は1.25倍になり、48名が残念な結果になった。'}
                imgResult={img1}
            />
            <CommentCard 
                title={'【高知商業(総マネ)】　（定員：140名）'}
                text={'第3回は昨年を下回ったものの、他の回はすべて昨年を上回り、192名が受験し、最大52名がB日程試験を受験する結果になってしまった。'}
                imgResult={img2}
            />
            <CommentCard 
                title={'【高知工業(建築)】　（定員：40名）'}
                text={'人気の高校の1つ、年度によりどの学科が人気になるかは不明だが、今年のグラフは昨年とほぼ同数になっており、2年続けて1.30倍（今年は1.33倍）を越える結果になった。'}
                imgResult={img3}
            />
           
        </>
    );
}

export default Comment;