import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {device} from '../MainContents/Style/StyleDevice'

const BtnClick1 = () => {
    let element = document.getElementById("blog1");

    // Toggle 切替
    let a = element.className;
    if (a === 'd-block') {
        element.className = "d-none";
    } else {
        element.className = "d-block";
    }
}

const BtnClick2 = () => {
    let element2 = document.getElementById("blog2");

    // 表示・非表示を切り替える
    let toggleNews = element2.className;
    if (toggleNews === 'd-block') {
        element2.className = "d-none";
    } else {
        element2.className = "d-block";
    }
}
// 冬期休業
// const BtnClick3 = () => {
//     let element3 = document.getElementById("blog3");

//     // 表示・非表示を切り替える
//     let toggleNews = element3.className;
//     if (toggleNews === 'd-block') {
//         element3.className = "d-none";
//     } else {
//         element3.className = "d-block";
//     }
// }

const BtnScope = () => {
    let element3 = document.getElementById("scope");
    let scopeToggle = element3.className;
    element3.className = scopeToggle=== 'd-block'? "d-none":"d-block";
}


// 重要なお知らせ
const NewsPadding = styled.div`
    padding-top: 70px;
    padding-bottom: 70px;
`;

const BtnNone =styled.button`
    border: 0;
    background-color: #fff;
    margin-bottom:10px;
`;

const NewsTitle =styled.h3`
    border-bottom: 2px solid #2cc12c;
    @media ${device.laptop}{
        font-size: 18px;
     }
`;

const NewsComment =styled.div`
    padding: 20px;
    font-weight: bold;
`;
const BorderNoneMarginWid = styled.div`
    border: 0!important;
    margin:0 auto;
    width: 80%;
    margin-top:50px;
    @media ${device.laptop}{
        width: 90%;
     }
`;

const NewsBackColor = styled.div`
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #009d80;
    color: #fff;
`;

const Hatena = styled.span`
transform: rotate(
    10deg
    );
        -moz-transform: rotate(-10deg);
        -webkit-transform: rotate(
    10deg
    );
        display: inline-block;
        padding-left: 10px;
`;

const RegisterMail =styled.a`
    color: #00ad80;
`;


const TopInformation = () => {
    const registerMail = 'https://toitumosi.jp/join/registmail.html';
    const SvgUrl = 'http://www.w3.org/2000/svg';
    const SvgD = 'M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z';
    const url ='/toitu/';
    return (
        <>
        {/* お知らせ */}
        <BorderNoneMarginWid className="card mb-3">
                <div className="row g-0" >
                    <NewsBackColor className="col-md-3 d-flex align-items-center justify-content-center" >
                        <h3 >お知らせ</h3>
                    </NewsBackColor>

                    <NewsPadding className="col-md-9">
                        <div className="card-body">

                            {/* <BtnNone className="btn-none" onClick={BtnClick3}>
                                <NewsTitle>冬季休業のお知らせ</NewsTitle>
                            </BtnNone><br /> */}

                                <div className="d-none " id="blog3" value="3">
                                    <NewsComment className="Blog-comment">
                                        <p>統一模試事務局は12/29～1/3日までお休みになります</p>
                                    </NewsComment>
                                </div>
                            <BtnNone className="btn-none" onClick={BtnClick2}>
                                <NewsTitle>受験者および保護者のみなさまへ</NewsTitle>
                            </BtnNone><br />

                                <div className="d-block " id="blog2" value="2">
                                    <NewsComment className="Blog-comment">
                                        <p>体調がすぐれない時は、受験を控えてください</p>
                                        <p className="bt-0">テストの返送について</p>
                                        <p className="mt-0">※郵便の土曜配達が無くなりましたので、試験実施翌日から8日～10日で届く予定です</p>
                                        <p className="mt-0">※近隣の方が配達されても自宅には配達されないということがあります。しばらくお待ち下さい。</p>
                                    </NewsComment>
                                </div>

                            <BtnNone className="btn-none" onClick={BtnClick1}>
                                <NewsTitle>統一お知らせメール</NewsTitle>
                            </BtnNone><br />


                            <div className="d-none " id="blog1" value="1">
                                <NewsComment className="Blog-comment">
                                    <ul>
                                    <li className="pt-1">次回以降の試験結果の登録や高校入試に関わる情報をお知らせいたします。</li>
                                    <li className="pt-1">※予約受付け開始時にお知らせメールが届きます。</li>
                                    </ul>
                                    <RegisterMail href={registerMail} target="_blank" style={{color: '#00ad80'}}>
                                        お知らせメールの登録はこちら&ensp;<svg xmlns={SvgUrl} width="20" height="20" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d={SvgD}/></svg>
                                    </RegisterMail>
                                </NewsComment>
                            </div>

                            {/*  範囲表が確定するまで表示 */}
                            <BtnNone className="btn-none mt-3">
                                {/* <NewsTitle>範囲表を掲載致しました。</NewsTitle> */}
                                <NewsTitle> <Link  to={url + 'ForToNextYear'} style={{color: 'black'}} > 「本番入試に向けて」を更新（2024/4/27）</Link></NewsTitle>
                                {/* <div className="d-none " id="scope" value="1">
                                <NewsComment className="Blog-comment">
                                    <a href="https://www.tosajuku.com/toitu_test_Scope.pdf" target="_blank" style={{color:'black'}}>範囲表はこちら</a>
                                </NewsComment>
                                </div> */}
                            </BtnNone><br />
                            {/* <BtnNone className="btn-none mt-3" onClick={BtnScope}> */}
                                {/* <NewsTitle>範囲表を掲載致しました。</NewsTitle> */}
                                {/* <NewsTitle>2021年度の統一模試範囲について</NewsTitle>
                                <div className="d-none " id="scope" value="1">
                                <NewsComment className="Blog-comment">
                                    <a href="https://www.tosajuku.com/toitu_test_Scope.pdf" target="_blank" style={{color:'black'}}>範囲表はこちら</a>
                                </NewsComment>
                                </div>
                            </BtnNone><br /> */}

                            <BtnNone className="btn-none mt-3" >
                                <NewsTitle>ホームページリニューアルしました。(07/28)</NewsTitle>
                            </BtnNone>
                        </div>
                    </NewsPadding>
                </div>
             
            </BorderNoneMarginWid>
        </>
    );
}

export default TopInformation;
