import React from 'react';
import styled from 'styled-components';
import {device} from '../MainContents/Style/StyleDevice'

const Heading = styled.h2`
    margin-bottom: 30px;
    padding: 15px;
    font-size: 24px;
    font-size: 1.5rem;
    background-color:#009d80;
    color: #fff;
    border-radius: 7px;
`;

const MarginWid = styled.div`
    width: 80%;
    margin:0 auto;
    margin-bottom: 30px;
    border:2px solid #f5f5f5;
    border-radius: 3px;
    @media ${device.laptop}{
        width:100%;
    }
`;

const CommentCard = props => {
    const {title, text, imgResult} = props; 
    return(
        <>
            <MarginWid className    ="card mb-3">
                <div className="row g-0">
                    <div className="col-md-4">
                        <div className="card-body ">
                            <h5 className="card-title text-center font-weight-bold" style={{color:'#00ad80'}}>{title}</h5>
                             <p className="card-text">{text}</p>
                        </div>
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <img src={imgResult} width={'100%'} alt="" />
                        </div>
                    </div>
                </div>
            </MarginWid>
        </>
    );
}

export default CommentCard;