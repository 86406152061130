import styled, {keyframes} from 'styled-components';
import {device} from '../Style/StyleDevice'

const AppLogoSpin = keyframes`
    0% {
        opacity:0;
        transform: translateY(64px);
    }
    100% {
        opacity:1;
        transform: translateY(0);
    }
`;
const Heading = styled.h1`
    font-size: 2.2rem;
    letter-spacing: -.045em;
    animation: ${AppLogoSpin} 0.4s linear;
    @media ${device.laptop}{
        font-size: 1.6rem;
        font-weight: 700;
    }
`;

const Hr =styled.hr`
    height: 0.1px!important;
    margin-bottom: 30px;
    @media ${device.laptop}{
        margin-top: -1px;
    }
`;

const English =styled.div`
    height: 5.1rem;
    z-index: 2;
    padding-left: 70px;
    animation: ${AppLogoSpin} 0.4s linear;
    @media ${device.laptop}{
        height: 4.5rem;
        padding-left: 30px;
        overflow: hidden;
    }
`;
const EnglishTitle =styled.span`
    color: gray;
    opacity: 0.2;
    font-size: 4.9rem;
    z-index: 1;
    @media ${device.laptop}{
        font-size: 3.7rem;
        font-weight: 500;
    }
`;


const PageTitle = props => {
    return (
        <>
            <English>
                <EnglishTitle>{props.englishText}</EnglishTitle>
            </English>
            <Heading>{props.title}</Heading>
            <Hr />
        </>
    );
}

export default PageTitle;