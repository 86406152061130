import React, {useState} from 'react';
import AudioList from './AudioList.jsx'
import AudioListYear from './AudioListYear.jsx'

import ImgSub from '../../img/IMG_0787.jpg'
import ImgSub2 from '../../img/IMG_0792.jpg'

import styled from 'styled-components';

const BookMainScope = styled.div`
    background: #f5f5f5;
    height: 400px;
    margin-bottom: 30px;
    z-index: 1;
    position: relative;
    padding: 20px;
`;
const Img = styled.img`
    width: 100%;
    height: 50vh;
    object-fit: cover;
    margin-left: 100px;
`;

const TitleColor = styled.h2`color: #00ad80;`
const FlowText = styled.p`
    text-decoration: none;
    padding-bottom: 3px; /* 文字と下線に隙間を作る */
    color: #000;
    display: block;
    margin-top: 5px;
    &:after {
        content: '';
        width: 0;
        transition: all 0.3s ease;
        border-bottom: 1px solid #000;
        display: block;
    }
    &:hover:after {
        width: 70%;
        border-bottom: 1px solid #000;
    }
`;

const BookIntroduction = () => {
    const [view, setView] = useState(0);
    return (
        <>
            <BookMainScope> 
                <TitleColor>2023年度用　高知県公立高校入試　統一模試</TitleColor>
                <div>
                    統一模試で昨年実施した5回分の問題に解答と詳しい解説を付けた問題集。<br />
                    県下有名書店で好評発売中！
                </div>    
                <FlowText onClick={() => setView(view + 1)}><span style={{color: '#00ad80',marginTop: '3px'}}>2023年 第1回～第5回のリスニング音声</span>&ensp;↓</FlowText>
                <p style={{float:'right'}}>統一模試問題集(1,430円・税込)</p>
                {view % 2 ===0 ? <Img src={ImgSub} /> : <AudioList viewTrue={'true'} />}  
            </BookMainScope> 

            <div style={{marginTop: '30%'}}></div>    
            <BookMainScope> 
                <TitleColor>高知県公立高校入試問題 <small>高知県教育委員会</small></TitleColor>
                
                <div>
                    2010年～2023年英語リスニング音声を聞くことができます。
                </div>    
                <FlowText  onClick={() => setView(view + 1)}><span style={{color: '#00ad80',marginTop: '3px'}}>2010年～2023年のリスニング音声</span>&ensp;↓</FlowText>
                {/* <p>※入試過去問題集は7月26日より発売予定です。</p> */}
                {view % 2 ===0 ? <Img src={ImgSub2} /> : <AudioListYear viewTrue={'true'} />}
            </BookMainScope>  

            <div style={{marginTop: '50%'}}></div>    
        </>
    );
}

export default BookIntroduction;