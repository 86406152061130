import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ScrollToTopOnMount from '../MainContents/AutoScrollTop'; 
import PageTitle from '../MainContents/PageTitle/PageTitle.js'

const MarginTop = styled.div`
    margin:0 auto;
    width: 90%;
    margin-top: 100px;
`;

const Heading = styled.h2`
    margin-bottom: 30px;
    padding: 15px;
    font-size: 24px;
    font-size: 1.5rem;
    background-color:#009d80;
    color: #fff;
    border-radius: 7px;
`;
const SubTextLi = styled.p`
    margin-top: 3px;
    list-style-type: none; 
    position: relative; 
    padding-left: 0.6em;
    &:before {
        border-radius: 50%; /*丸くする*/
        width: 5px; /*点の幅*/
        height: 5px; /*点の高さ*/
        display: block;
        position: absolute; /*絶対配置*/
        left: 0; /*点の位置*/
        top: 0.6em; /*点の位置*/
        content: "";
        background: #009d80; /*点の色*/
    }
`;

const Button = styled.button`
    border: 2px solid #00ad80;
    background: #fdfdfd;
    padding: 10px;
    margin-left: 10px;
    font-weight: bold;
`;

const Alink = styled.a`
    color: black;
`;

const Pointer = styled.span`
    color: #fff;
    display: inline-block;
    background: #00ad80;
    width: 25px;
    height: 25px;
    border-radius: 50%;
`;


const UserResultTable = () => {
    const url ='/toitu/UserResultTable/';
    return (
        <MarginTop>
            <ScrollToTopOnMount />
            <PageTitle title={'個人成績表の見方'} englishText={'IndividualPerformanceTable'} />
                <Heading>郵送による成績表</Heading>
                <div className="card mb-3 border-0">
                    <div className="row g-0">
                        <div className="col-md-12">
                            <div className="card-body">
                                <p className="card-text">
                                受験者個人の成績データ(左半分)<br />
                                あなたの得点・偏差値、科目の最高点・平均点<br />
                                受験者全体の中でのあなたの順位と評価(右半分)
                                </p>
                                
                                    <SubTextLi>全受験者中のあなたの順位 :五教科の合計点で全受験者中のあなたの順位を表示しています。</SubTextLi>
                                    <SubTextLi>志望校別のあなたの順位    :志望校別にあなたの順位・合格の可能性(Ａ～Ｄ)を表示しています。</SubTextLi>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <Heading>WEB成績判定</Heading>
                <div className="card mb-3 border-0">
                    <div className="row g-0">
                        <div className="col-md-12">
                            <div className="card-body">
                                
                                    <SubTextLi>高知県統一模試を受験された生徒は、受験･整理番号を使い登録し使用することが出来ます。</SubTextLi>
                                    <SubTextLi>第1回から第5回までの成績をまとめて、閲覧することができます。</SubTextLi>
                                    <SubTextLi>成績表により、各回の点数･平均点･偏差値･評価を表示しています。</SubTextLi>
                                    <SubTextLi>成績チャートにより、学力をわかりやすく表示しています。</SubTextLi>
                                    <SubTextLi>全体分布により、各教科の学力が全体のなかでどこに位置しているか、わかりやすく表示しています。。</SubTextLi>
                                    <SubTextLi>成績比較チャートと判定により、限られた学校だけの判定だけでなく、高知県内の公立高校を比較･判定することが出来ます。</SubTextLi>
                                    {/* <SubTextLi><a href="https://toitumosi.jp/img/kai6.pdf" style={{color: 'rgb(0, 173, 128)'}}>第６回　統一模試&ensp;偏差値判定基準&ensp;(PDF)</a></SubTextLi>
                                    <SubTextLi><a href="https://toitumosi.jp/img/2021_distribution_6.pdf" style={{color: 'rgb(0, 173, 128)'}} >第６回　統一摸試&ensp;学校別分布表&ensp;(PDF)</a></SubTextLi> */}                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                        <div className="row" style={{marginBottom: '15px'}}>
                            <div className="col-sm mt-3">
                                <Button><Alink href="https://toitumosi.jp/MyScore/login/loginf.php" target="_blank">WEB成績判定ログインページ</Alink>
                                        <Pointer>→</Pointer>
                                </Button>
                            </div>
                            <div className="col-sm mt-3">
                                <Button><Alink href="https://toitumosi.jp/MyScore/login/userRegister.php" target="_blank">WEB成績判定登録ページ</Alink>
                                        <Pointer>→</Pointer>
                                </Button>
                            </div>
                            <div className="col-sm mt-3">
                                <Link  to={url + 'Sample'} >
                                    <Button >WEB成績判定サンプルページ　<Pointer>→</Pointer></Button>
                                </Link>
                            </div>
                        </div>   
                </div>
        </MarginTop>
    );
}

export default UserResultTable;