import React from 'react';
import Answer from './Answer.jsx';
import { Link } from 'react-router-dom';
// 解答の内容はここで編集
const AnswerField = (props) => {
    return (
        <>
            {/* <ScrollToTopOnMount /> */}
            {/* 質問に応じての答えを表示する */}
            {props.id === '1' &&
                <Answer id={props.id}
                    answer={'予約が必要な会場がございます。'}
                    answer2="お近くの会場に８:４５AMまでには来てください。"
                    answer3="開始時間が異なる会場がありますので、公開会場案内で確認してください。"
                    answer4="会場では本人自宅の正確な住所・氏名・電話番号の記入が必須になります。"
                />
            }
            {props.id === '2' &&
                <div class="mt-3">
                    <p>予約キャンセル・会場変更の方は
                        <a href="https://tosajuku.info/tsjk_webpage/reservation/alteration/" style={{ color: '#00ad80' }} target="_blank">
                            こちら
                        </a>
                    </p>
                </div>
            }
            {props.id === '3' &&
                <Answer id={props.id}
                    answer="メモ用紙等（予約番号記入券）の紙に書いてお持ちください"
                    answer2="受信メールを見せるでも大丈夫です。"
                />
            }
            {props.id === '4' &&
                <Answer id={props.id}
                    answer="携帯会社側での何らかの理由により、統一からのメールを受信できない又は迷惑メールフォルダに振り分けられている場合があります。"
                    answer2=" 各キャリアのサイトにて＠tosajuku.co.jpからのメールを受信できるよう、受信設定または拒否設定の解除をお願いいたします。"
                />
            }
            {props.id === '5' &&

                <div className="card-body">
                    <p class="card-text"><Link to={'/toitu/Hall'}>「公開会場案内」</Link>を参考にしてください。</p>
                </div>

            }
            {props.id === '6' &&
                <Answer id={props.id} answer="筆記用具と受験料（４０００円）、お持ちの方は受験番号証（初めての人はありません）を持って来てください。" />
            }
            {props.id === '7' &&
                <Answer id={props.id} answer="制服の必要はありません。普段着でどうぞ！" />
            }
            {props.id === '8' &&
                <Answer id={props.id}
                    answer='範囲表はこちら'
                />
            }
            {props.id === '9' &&
                <Answer id={props.id} answer="※郵便の土曜配達が無くなりましたので、試験実施翌日から8日～10日で届く予定です" />
            }
            {props.id === '10' &&
                <Answer id={props.id} answer="WEB成績判定に登録すると、志望校を変えて判定が見えるようになります。" />
            }
            {props.id === '11' &&
                <Answer id={props.id}
                    answer="誰かご家族の方がすでに受け取ってはいませんか？"
                    answer2="お通いの塾にまとめて返却されていませんか？　塾の先生にお尋ねください。"
                    answer3="住所・番地の記入が不十分な場合には、一度事務局の方に戻ってくる場合があり届かないことがあります。"
                    answer4="試験実施後２週間ほどしても届かない場合には事務局にご連絡ください。" />
            }
            {props.id === '12' &&
                <Answer id={props.id}
                    answer="申し訳ありません。細心の注意で行っておりますが、発見された場合にはお手数ですが事務局にご連絡ください。"
                    answer2="個人表を訂正して再発送させていただきます。"
                    answer3="受付は平日（月～金）午前９時より午後５時の間にご連絡ください。"
                />
            }
        </>
    )
}

export default AnswerField;